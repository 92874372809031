// firebase-config.ts
import {initializeApp} from "firebase/app";
import {Auth, getAuth} from "firebase/auth";
import {Firestore, getFirestore} from "firebase/firestore";

// Your web app's Firebase configuration
const devFirebaseConfig = {
    apiKey: "AIzaSyA2imwMYcxNkbkoXKUUrWt_hA0LLMYwUCo",
    authDomain: "dynamic-art-433118-s1.firebaseapp.com",
    projectId: "dynamic-art-433118-s1",
    storageBucket: "dynamic-art-433118-s1.appspot.com",
    messagingSenderId: "115878448122",
    appId: "1:115878448122:web:c94acb3748c6b5b02cf243",
    measurementId: "G-JHFEH3R8Q4"
};


const stagingFirebaseConfig = {
    apiKey: "AIzaSyAqV6-CTRbpREiVQFm33wE80NzQ3f3mU68",
    authDomain: "aaim-staging.firebaseapp.com",
    projectId: "aaim-staging",
    storageBucket: "aaim-staging.firebasestorage.app",
    messagingSenderId: "548010426440",
    appId: "1:548010426440:web:b7ecc6aede935482ccd87d",
    measurementId: "G-WWZ3W8QSCY"
};




// Initialize Firebase
const app = initializeApp(devFirebaseConfig);

// Initialize Firebase services
const auth: Auth = getAuth(app);
const db: Firestore = getFirestore(app);

export {auth, db};
